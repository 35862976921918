<template>
  <v-row>
    <v-col cols="3">
      <v-select
        outlined
        flat
        dense
        background-color="white"
        v-model="year"
        :items="listYear"
      ></v-select>
    </v-col>
    <v-col cols="3">
      <v-btn color="success" @click="excel">
        <v-icon>
          mdi-file-excel
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import fileDownload from "js-file-download";

export default {
  name: "report-sales",
  data() {
    return {
      year: moment().year(),
      listYear: [],
    };
  },
  methods: {
    async excel() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("report/sales", this.year)
        .then(response => {
          fileDownload(response.data, `report-sales-${this.year}.xlsx`);
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    getListYear(range) {
      const year = new Date().getFullYear();
      this.listYear = Array.from({ length: range }, (v, i) => year - range + i + 1);
    },
  },
  mounted() {
    this.getListYear(5);
  },
};
</script>

<style></style>
